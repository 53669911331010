import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { v4 as uuidv4 } from "uuid"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Section from "../components/Section"
import Hero from "../components/Hero"

const IndexPage = ({ data }) => {
  if (!data) return null

  const pageData = data.prismicHome || {}
  const { lang, type, url } = pageData || {}
  const alternateLanguages = pageData.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  return (
    <Layout location="Home" activeDoc={activeDoc}>
      <Seo
        title={pageData.data.title.text}
        description={pageData.data.description.text}
      />
      <Hero data={pageData.data} />
      {pageData.data.body &&
        pageData.data.body.map(section => (
          <Section key={uuidv4()} data={section} />
        ))}
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageQuery {
    prismicHome(lang: { eq: "en-us" }) {
      alternate_languages {
        uid
        type
        lang
        url
      }
      lang
      url
      type
      data {
        title {
          text
        }
        description {
          text
        }
        hero_title {
          raw
        }
        hero_subtitle {
          raw
        }
        card_link_text {
          text
        }
        card_static_text {
          text
        }
        cards {
          card_title {
            text
          }
          card_subtitle {
            text
          }
          card_icon
          card_link {
            uid
            type
            lang
            url
            link_type
          }
        }
        body {
          ... on PrismicHomeBodySectionStandard {
            slice_type
            primary {
              section_type
              heading {
                raw
              }
              subheading {
                raw
              }
              button_text {
                text
              }
              button_link {
                uid
                type
                lang
                url
                link_type
              }
            }
            items {
              column {
                raw
              }
            }
          }
          ... on PrismicHomeBodySectionCta {
            slice_type
            primary {
              heading {
                raw
              }
              text {
                raw
              }
              button_text {
                text
              }
              button_link {
                uid
                type
                lang
                url
                link_type
              }
              selected_subject {
                text
              }
            }
            items {
              image {
                url
                alt
                dimensions {
                  height
                  width
                }
                thumbnails {
                  mobile {
                    alt
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
